<template>
    <div>
        <div class="col-md-12" style="margin-top:20px">
            <div class="col-md-6">
                <h3 class="page-header">
                    <i class="fa fa fa-users animated bounceInDown show-info"></i>
                    Add Service Center Details
                </h3>
            </div>
        </div>
        <form @submit.prevent="onSubmit" @keydown="form.errors.clear($event.target.name)"
            class="p-8 lg:w-1/2 md:mx-auto">
            <div class="col-md-12 ">

                <div class="panel panel-cascade">
                    <div class="panel-body">
                        <div class="form-horizontal cascde-forms">
                            <div class="form-group row CHK">
                                <label class=" col-lg-4 col-md-3 control-label" style="text-align:right">Add
                                    Company<span style="color: rgba(255, 0, 0, 0.67)">*</span>
                                </label>
                                <div class="col-lg-6 col-md-9">
                                    <input type="text" class="form-control form-cascade-control input-small"
                                        v-model="form.text1" required>
                                    <span class="invalid-feedback" role="alert">
                                        <strong></strong>
                                    </span>
                                </div>
                            </div>
                            <div class="form-group row CHK">
                                <label class=" col-lg-4 col-md-3 control-label" style="text-align:right">Add
                                    Details<span style="color: rgba(255, 0, 0, 0.67)">*</span>
                                </label>
                                <div class="col-lg-6 col-md-9">
                                    <textarea type="text" class="form-control form-cascade-control input-small"
                                        v-model="form.text2" required></textarea>
                                    <span class="invalid-feedback" role="alert">
                                        <strong></strong>
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="row" style="text-align:center;">
                                    <input type="button"
                                        style="background-color:#495b79!important;margin-top:23px;margin-left:35px;"
                                        value="Submit" @click="onSubmit()"
                                        class="btn bg-primary text-white btn-md chkcondition">
                                    <input type="button"
                                        style="background-color:#495b79!important;margin-top:23px;margin-left:35px;"
                                        value="Cancel" @click="cancel()"
                                        class="btn bg-primary text-white btn-md chkcondition">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="cardbox1">
                <div class="col-md-12">
                    <table class="table table-font">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Company</th>
                                <th>Center Details</th>
                                <th>Edit</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item, index in centerdetails" :key="item.id">
                                <th>{{ index + 1 }}</th>
                                <th>{{ item.text1 }}</th>
                                <th>{{ item.text2 }}</th>
                                <th><button type="button" class="btn btn-success p-0" @click="editService(item)"><i
                                            class='bx bxs-edit'></i></button></th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </form>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Constants from "../../components/utilities/Constants.vue";
export default {
    mixins: [Constants],
    components: {

    },
    data() {
        return {
            centerdetails: [],
            form: new window.Form({
                id: 0,
                text1: '',
                active: 1,
                text2: ''
            })
        }
    },
    mounted() {
        this.refresh();

    },
    computed: {
        ...mapGetters([
            'edititem'
        ]),
    },
    methods: {
        onSubmit: function () {
            if (this.form.text1 != '') {
                this.$notify({
                    text: 'Submitted',
                    type: 'warning',
                    duration: '80000',
                    speed: '800',
                    closeOnClick: false
                })
                this.$store.commit('assignloadingstatus', 1)
                this.form.submit(this, 'post', 'api/createupdate/servicecenter')
                    .then(response => this.onSuccess(response))
                    .catch(error => this.errorShow(error));
            }
        },
        onSuccess: function (data) {
            if (data != null) {
                // this.$store.commit('assignviewno', 1)
                //location.reload()
                this.$notify({
                    text: 'Details Saved Successfully',
                    type: 'success',
                    duration: '-1',
                    speed: '100',
                    closeOnClick: false
                })
                this.refresh();
                this.$store.commit('assignloadingstatus', 0)
            } else {
                this.$store.commit('assignloadingstatus', 0)
            }
        },
        cancel() {
            // this.$store.commit('assignviewno', 1)
            this.$store.commit('assignloadingstatus', 0)
            this.form.id = 0,
                this.form.text1 = ''
            this.form.text2 = ''
        },
        refresh() {
            // 
            window.axios.post('api/fetch/servicecenter')
                .then(response => this.onFetchSuccess(response.data))
                .catch(error => this.errorShow(error));
        },
        onFetchSuccess(data) {
            this.centerdetails = data
        },
        errorShow() {
            this.$store.commit('assignloadingstatus', 0)
            alert("Something went wrong, please try after sometime.")
        },
        editService(item) {
            this.form.text1 = item.text1
            this.form.text2 = item.text2
            this.form.id = item.id
        }
    }
}

</script>
